







































import {
  BootstrapButton,
  BootstrapImage,
  BootstrapLink,
  BootstrapTheme,
  Link
} from '@movecloser/ui-core'
import { Component, Inject, Prop } from 'vue-property-decorator'

import { ImageAddon } from './Image.contracts'
import { AbstractAddonUi } from '../AbstractAddon.ui'
import { UiHeading } from '../../../../atoms/UiHeading/UiHeading.vue'
import { Image, ImageFile, ImageRatio } from '../../../../../models'
import { isRelated } from '../../../../../services'
import { toBootstrapImageProps } from '../../../../../support'

/**
 * UI component for the `MosaicModuleAddonType.Image`.
 */
@Component<ImageAddonUi>({
  name: 'ImageAddonUi',
  components: { BootstrapButton, BootstrapLink, UiHeading, BootstrapImage },
  async prefetch (): Promise<void> {
    await this.fetchRelated()
  }
})
export class ImageAddonUi extends AbstractAddonUi {
  @Prop({ type: Object, required: false })
  public readonly thumbnail?: ImageAddon['thumbnail']

  @Inject({ from: 'isPhone', default: false })
  private readonly isPhone!: boolean

  public readonly BootstrapTheme = BootstrapTheme

  public resolvedThumbnail: Image | null = null

  private imageRatio = ImageRatio.Original

  public get hasLink (): boolean {
    return !!this.link && typeof this.link !== 'undefined' &&
      (Object.prototype.hasOwnProperty.call(this.link, 'target') &&
        typeof (this.link as Link).target !== 'undefined')
  }

  public async fetchRelated (): Promise<void> {
    await this.resolveThumbnail()
  }

  public get image (): Image {
    if (!this.resolvedThumbnail) {
      return {
        src: ''
      }
    }

    return this.resolvedThumbnail
  }

  private async resolveThumbnail (): Promise<void> {
    if (typeof this.thumbnail === 'undefined' || !isRelated(this.thumbnail)) {
      return
    }

    let imageFile: ImageFile
    try {
      imageFile = await this.relatedService.resolve(this.thumbnail) as unknown as ImageFile
    } catch (e) {
      console.warn(e)
      return
    }

    this.resolvedThumbnail = toBootstrapImageProps(imageFile, this.imageRatio)
  }
}

export default ImageAddonUi
